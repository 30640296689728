import instance from "./api/api_instance";

export const getAllAdminOrderReports = async (credentials: object) => {
    const response = await instance.get("AdminReports/GetAdminOrderReports", {
      params: credentials,
    });
    return response.data;
  };

  export const getOrderReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReports/AdminOrderReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };

  export const getAllCustomerOrderReports = async (credentials: object) => {
    const response = await instance.get("AdminReports/GetCustomerOrderReports", {
      params: credentials,
    });
    return response.data;
  };

  export const getCustomerReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReports/CustomerOrderReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };
  
  export const getAdminReports = async (url : any, credentials: Object) =>{
    const response = await instance.get(url, {
      params: credentials,
    });
    return response.data;
  }

  export const getAdminReportExportToExcel = async (url : any, credentials: object) => {
    const response = await instance.get(url, {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };

  export const getAllCustomerReportExportToExcel = async (credentials: object) => {
    const response = await instance.get("AdminReports/CustomerReportExportToExcel", {
      params: credentials,
      responseType: 'blob',
    });
    return response.data;
  };